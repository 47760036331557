import {
    Toast
} from "vant";
import {
    DownloadImage
} from '@/api/api'
import {
    appHost
} from '@/utils/host'
import {parseUrl} from '@/utils/utils'
export const Ocr = {
    data() {
        return {
            // idcard_front: '/static/idcard_front.png',
            // idcard_back: '/static/idcard_back.png',
            idcards: [{
                    image: "",
                    // image: "http://ecasev2-api.papv2.sungotech.com/upload/image/202108/02/cdb68e0ecbb8fb2998f1b634640640a6.jpg",
                    type: "back" //国徽
                },
                {
                    image: "",
                    //  image: "http://ecasev2-api.papv2.sungotech.com/upload/image/202108/02/5357e7bb0e1352171111e7f305af4de1.jpg",
                    type: "front" //人像
                }
            ],
            photo: '',
            baseFileUrl: appHost
        }
    },
    mounted() {

    },
    methods: {
        getImgByteSize(data) {
            let size = 0
            if (data) { // 获取base64图片byte大小
                const equalIndex = data.indexOf('='); // 获取=号下标
                if (equalIndex > 0) {
                const str = data.substring(0, equalIndex); // 去除=号
                const strLength = str.length;
                const fileLength = strLength - (strLength / 8) * 2; // 真实的图片byte大小
                size = Math.floor(fileLength); // 向下取整
                } else {
                const strLength = data.length;
                const fileLength = strLength - (strLength / 8) * 2;
                size = Math.floor(fileLength); // 向下取整
                }
            }
            return (size * 1.657)
        },
        chooseImage(side) {
            this.$wechat.chooseImage({
                count: 1,
                // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
                sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
                success: async res => {
                    try {
                        const serverId = res.localIds[0];
                        // alert(JSON.stringify(res.localIds[0]))
                        this.$wechat.getLocalImgData({
                            localId:res.localIds[0],
                            success:async response => {
                                let localData = response.localData;
                                this.picList = response
                                let itemSize = await this.getImgByteSize(localData);//获取base64大小
                                console.log(itemSize,"itemSize")
                                // alert(itemSize)
                                if(itemSize>20480000){
                                    Toast("图片过大，请重新选择图片");
                                }else{
                                    await this.uploadImage(serverId, side);
                                }
                            }
                        })
                    } catch (err) {
                        Toast(err.response.data.message);
                    }
                },
                fail: function () {
                    Toast("选图失败");
                }
            });
            // this.images = ['http://ecasev2-api.papv2.sungotech.com/upload/image/202108/10/0c77ddbfccbdb2cd19f0d829dbe3c7af.jpg']
            // this.ocr();
        },
        uploadImage(serverId, side) {
            return new Promise((resolve, reject) => {
                this.$wechat.uploadImage({
                    localId: serverId,
                    isShowProgressTips: 1,
                    success: async res => {
                        var serverId = res.serverId; // 返回图片的服务器端ID
                        Toast.loading({
                            duration: 0, // 持续展示 toast
                            forbidClick: true
                        });
                        try {
                            let res = await DownloadImage({
                                params: {
                                    serverId: serverId
                                }
                            })
                            if (side == "front") {
                                this.idcard_front = parseUrl(res, this.baseFileUrl);
                                this.idcards[1].image = parseUrl(res, this.baseFileUrl);
                            } else if (side == "back") {
                                this.idcard_back = parseUrl(res, this.baseFileUrl);
                                this.idcards[0].image = parseUrl(res, this.baseFileUrl);
                            } else {
                                this.photo = parseUrl(res, this.baseFileUrl)
                                this.images.push(parseUrl(res, this.baseFileUrl))
                            }
                            Toast.clear();
                            if ((this.idcards[0].image && this.idcards[1].image) || this.photo) {
                                this.ocr()
                                resolve()
                            } else {
                                resolve()
                            }


                        } catch (err) {
                            Toast.clear();
                            reject(err.response.data.message);
                        }

                    },
                    fail: function () {
                        Toast("图片上传失败");
                    }
                });
            });
        },

        fileUploadToOcr() {
            if ((this.idcards[0].image && this.idcards[1].image) || this.photo) {
                this.ocr()
            }
        }
    }
}