<template>
  <div class="ocr">
    <div class="proj_box">
      <div class="proj_title">参与项目</div>
      <div class="proj_name">{{ proj_name }}</div>
    </div>
    <div class="uploadTitle">
      <span>上传银行卡照片</span>
    </div>
    <div class="uploadStandard">每次最多上传0/2张，单张文件不超过5MB</div>
    <div class="ocrBox">
      <template v-if="isWeixin">
        <div class="upload-img" @click="chooseImage('front')">
          <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
        </div>
        <div class="upload-img" @click="chooseImage('back')">
          <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
        </div>
      </template>
      <template v-else>
        <ImageUploader class="upload-img" v-model="img_front" single @change="uploadFront">
          <template slot="content">
            <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
          </template>
        </ImageUploader>
        <ImageUploader class="upload-img" v-model="img_back" single @change="uploadBack">
          <template slot="content">
            <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
          </template>
        </ImageUploader>
      </template>
    </div>

    <div class="identity">填写银行卡相关信息</div>

    <van-cell-group class="information">
      <van-field :value="form.name" label="姓名" disabled />
      <van-field :value="form.mobile" label="手机号" disabled />
      <van-field label="银行卡号" v-model="form.number" placeholder="请填写银行卡号" />
      <van-field label="开户行" readonly name="picker" placeholder="请选择开户行" :value="form.bankname" rows="1" autosize maxlength="500" type="textarea" right-icon="arrow-down" @click="showBankList = !showBankList" />
      <van-popup v-model="showBankList" round position="bottom" :style="{ height: '400px' }" @click-overlay="showBankList = false">
        <!-- :columns="getOption(bankList, bankKeyword)" -->
        <van-picker :default-index="findIndex(bankList, form.bankname)" show-toolbar :columns="getOption(bankList, bankKeyword)" value-key="value" @cancel="showBankList = false" @confirm="confirmBank">
          <template #title>
            <van-search class="bank_search" v-model="bankKeyword" input-align="left" shape="round" placeholder="搜索开户行名称" @clear="bankKeyword = ''" />
          </template>
        </van-picker>
      </van-popup>
      <van-field label="开户支行" v-model="form.bankbranch" placeholder="请填写时加上省市信息" />
    </van-cell-group>
    <div class="bhinfo" v-if="remark">
      驳回原因：
      <span>{{ remark }}</span>
    </div>
    <div class="btn">
      <van-button round type="info" size="large" class="up" :disabled="disabled" @click="next">提交审核</van-button>
    </div>
    <div class="goBack" @click="linkToregocr">
      <img src="/static/goBack.png" />
    </div>
  </div>
</template>

<script>
import { Ocr } from '@/components/ocr.js'
import ImageUploader from '@/components/ImageUploader'
import { Dialog, Toast } from 'vant'
import { getRegister, addBankList } from '@/api/api'
import { mapState, mapActions } from 'vuex'
export default {
  name: '',
  components: {
    ImageUploader
  },
  data() {
    return {
      disabled: false,
      idcard_front: '/static/bankcard_front.png',
      idcard_back: '/static/bankcard_back.png',
      img_front: [], // 非微信环境上传身份证正面
      img_back: [], // 非微信环境上传身份证反面
      remark: '', //驳回原因
      form: {
        name: '',
        mobile: '',
        number: '',
        bankname: '',
        bankbranch: ''
      },
      bankList: [],
      showBankList: false,
      bankKeyword: '' // 开户银行关键字
    }
  },
  mixins: [Ocr],
  computed: {
    ...mapState(['userId', 'registerInfo', 'proj_name', 'otherDoctorInfo'])
  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO']),
    ocr() {},
    async initData() {
      const data = await addBankList({ project_doctor_id: this.$route.query.doctorId })
      console.log(data, 'data')
      this.bankList = data.options
      console.log(this.otherDoctorInfo, 'other')
      if (this.otherDoctorInfo) {
        this.remark = this.otherDoctorInfo.remark
        for (let key in this.form) {
          if (this.otherDoctorInfo[key]) {
            this.form[key] = this.otherDoctorInfo[key]
          }
        }
        if (this.otherDoctorInfo.bankcard_imgs) {
          if (this.otherDoctorInfo.bankcard_imgs[0]) {
            this.idcard_front = this.otherDoctorInfo.bankcard_imgs[0]
            this.idcards[0].image = this.otherDoctorInfo.bankcard_imgs[0]
          }
          if (this.otherDoctorInfo.bankcard_imgs[1]) {
            this.idcard_back = this.otherDoctorInfo.bankcard_imgs[1]
            this.idcards[1].image = this.otherDoctorInfo.bankcard_imgs[1]
          }
        }
      }
      this.form.name = this.$route.query.name
      this.form.mobile = this.$route.query.mobile
    },
    async next() {
      console.log(this.idcards, 'this.idcards')
      if (!this.idcards[0].image || !this.idcards[1].image) {
        Toast('请上传证件照片')
        return false
      }
      let postdata = {
        pre_doctor_id: this.$route.query.doctorId,
        number: this.form.number,
        bankname: this.form.bankname,
        bankbranch: this.form.bankbranch,
        images: [this.idcard_front, this.idcard_back]
        // images: ['/upload/export/202211/68187-胡平平/身份证正反面.jpg', '/upload/export/202211/68187-胡平平/身份证正反面.jpg']
      }
      this.$store.commit('setOtherDocRgflag', true)
      await getRegister(postdata)
        .then(res => {
          console.log(res, 'res')
          this.$router.replace({
            path: 'checking'
          })
        })
        .catch(err => {
          Toast(err.response.data.message)
        })
    },
    findIndex(arr, val) {
      for (let i in arr) {
        let ele = arr[i]
        if (ele.name == ele.val) {
          // console.log('findIndex', i)
          this.$forceUpdate
          return i
        }
      }
    },
    getOption(list, val) {
      let arr = list.filter(item => {
        if (item.value) {
          return item.value.indexOf(val) != -1
        } else if (item.name) {
          return item.name.indexOf(val) != -1
        }
      })
      return val ? arr : list
    },
    confirmBank(val) {
      if (val) {
        this.form.bankname = val.value
        this.showBankList = false
      } else {
        Toast('请先输入关键字查找开户行')
      }
    },
    linkToregocr() {
      this.$store.commit('setOtherDocRgflag', true)
      this.$router.replace({
        name: 'regOcr',
        query: this.registerInfo
      })
    },
    uploadFront() {
      const len = this.img_front.length
      if (len > 0) {
        this.idcard_front = this.img_front[0]
        this.idcards[1].image = this.img_front[0]
      } else {
        this.idcard_front = '/static/bankcard_front.png'
        this.idcards[1].image = ''
      }
    },
    uploadBack() {
      const len = this.img_back.length
      if (len > 0) {
        this.idcard_back = this.img_back[0]
        this.idcards[0].image = this.img_back[0]
      } else {
        this.idcard_back = '/static/bankcard_front.png'
        this.idcards[0].image = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ocr {
  height: 100%;
  width: 100%;
  // padding: 0px 11px 0 9px;

  box-sizing: border-box;
  text-align: left;

  .proj_box {
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 16px 10px 16px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: 22px;

    .proj_title {
      font-size: 13px;
      height: 25px;
      background: #e2eeff;
      color: #4b99ff;
      text-align: center;
      line-height: 25px;
      border-radius: 3px;
      padding: 0 3px;
      font-weight: bold;
    }

    .proj_name {
      font-size: 15px;
      color: #292929;
      margin-left: 14px;
    }
  }

  .standard,
  .identity,
  .uploadTitle {
    font-size: 15px;
    color: #232323;
    margin-bottom: 13px;
    margin-left: 16px;
    margin-right: 16px;
    font-family: PingFangSC-Medium;
  }

  .bhinfo {
    margin-top: 10px;
    margin-left: 16px;
    margin-right: 16px;
    color: rgba(189, 49, 36, 1);
    font-size: 14px;
  }

  .pro_select {
    width: 163px;
    height: 22px;
    float: right;

    select {
      width: 100%;
      height: 100%;
      line-height: 20px;
      color: rgba(16, 16, 16, 1);
      font-size: 14px;
    }

    select:focus-visible {
      border: 1px solid rgba(125, 172, 248, 1);
    }
  }

  .uploadStandard,
  .uploadMark {
    // color: #A8A8A8;
    color: #565656;
    font-size: 12px;
    margin-bottom: 3px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .ocrBox {
    height: 128px;
    width: 100%;
    display: flex;
    justify-content: center;

    .upload-img {
      // height: 94px;
      // width: 139px;
      height: 96px;
      width: 165px;
      background-position: left top, right top, right bottom, left bottom;
      background-repeat: no-repeat;
      background-size: 16px;
      // padding: 20px;
      padding: 15px;

      .upload-img-a,
      .upload-img-b {
        width: 100%;
        height: 0;
        padding-top: 63%;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #fff;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
        border-radius: 5px;
      }
    }
  }

  .uploadMark {
    margin-bottom: 26px;
  }

  .standardImg {
    width: 100%;
    height: 90px;
    padding: 0 25px;
    box-sizing: border-box;
    margin-bottom: 20px;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  .information {
    // margin-bottom: 100px;
  }

  .tips {
    // color: #A8A8A8;
    color: #565656;
    font-size: 12px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    margin-bottom: 70px;
    box-sizing: border-box;
    text-align: center;
  }
}
</style>
