export const checkMail = (mail) => { //邮箱
    const reg = /^[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*@[a-zA-Z0-9]+([-_.][a-zA-Z0-9]+)*\.[a-z]{2,}$/
    const flag = reg.test(mail);
    return flag;

}

export const bankCardLength = (bankCard) => { //银行卡号
    // 目前一般最短16位，最长19位。深层次的校验无法进行
    const bankId = '' + bankCard;
    if (bankId.length < 16 || bankId.length > 19) {
        return false;
    } else {
        return true;
    }

}

export const checkCellphone = (cellphone) => { //电话号码
    const reg = /^1[0-9]{10}$/;
    const flag = reg.test(cellphone);
    return flag;
}

export const getSexById = (value) => {
    if (!value) {
        return "未知";
    } else if (value.length == 15) {
        return parseInt(value.substr(14, 1)) % 2 ? "男" : "女";
    } else if (value.length == 18) {
        return parseInt(value.substr(16, 1)) % 2 ? "男" : "女";
    } else {
        return "未知";
    }
}

export const getSex = (value) => {
    if (!value) {
        return "未知";
    } else if (value.length == 15) {
        return parseInt(value.substr(14, 1)) % 2 ? "先生" : "女士";
    } else if (value.length == 18) {
        return parseInt(value.substr(16, 1)) % 2 ? "先生" : "女士";
    } else {
        return "未知";
    }
}

export const getAge = (identify) => {
    var UUserCard = identify;
    if (UUserCard != null && UUserCard != '') {
        //获取年龄
        var myDate = new Date();
        var month = myDate.getMonth() + 1;
        var day = myDate.getDate();
        var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
        if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
            age++;
        }
        return age;
        //				
    }
}

export const isIdCardNo = (idCard) => { //身份证号码
    //15位和18位身份证号码的正则表达式
    var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;
    //如果通过该验证，说明身份证格式正确，但准确性还需计算
    if (regIdCard.test(idCard)) {
        if (idCard.length == 18) {
            var idCardWi = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); // 将前17位加权因子保存在数组里
            var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); // 这是除以11后，可能产生的11位余数、验证码，也保存成数组
            var idCardWiSum = 0; // 用来保存前17位各自乖以加权因子后的总和
            for (var i = 0; i < 17; i++) {
                idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
            }
            var idCardMod = idCardWiSum % 11; // 计算出校验码所在数组的位置
            var idCardLast = idCard.substring(17); // 得到最后一位身份证号码
            //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
            if (idCardMod == 2) {
                if (idCardLast == "X" || idCardLast == "x") {
                    return true;
                } else {
                    return false;
                }
            } else {
                //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
                if (idCardLast == idCardY[idCardMod]) {
                    return true;
                } else {
                    return false;
                }
            }
        }
    } else {
        return false;
    }
}
export const resolveDateStr = (dateStr) => { //转换日期格式
    if (dateStr) {
      let arr = dateStr.split(" ");
      let dayArr = arr[0].split("-");
      let str = dayArr[0] + "年" + dayArr[1] + "月" + dayArr[2] + "日" + " " + arr[1];
      return str;
    } else {
      return "某年某月";
    }
}
export const parseUrl = (url, baseUrl) => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
        return url;
    }else {
        return baseUrl + url;
    }
}