<template>
  <div class="image-upload">
    <!-- {{picList}} -->
    <div class="slot-uploader" v-if="$slots.content">
      <div class="slot-cover" v-if="isWeixin" @click="chooseImage()"></div>
      <template v-else>
        <van-uploader v-if="utm_source == 'iga_baidu'" class="slot-cover" :before-read="beforeRead" :max-count="num" :accept="getFileType(filetype)" :mutiple="androidAttrs2 ? true : false" :capture="androidAttrs2 ? 'camera' : null" preview-size="52">
          <div class="slot-cover-cont"></div>
        </van-uploader>
        <van-uploader v-else class="slot-cover" :before-read="beforeRead" :max-count="num" :accept="getFileType(filetype)" :mutiple="androidAttrs ? true : false" :capture="androidAttrs ? 'camera' : null" preview-size="52">
          <div class="slot-cover-cont"></div>
        </van-uploader>
      </template>
      <slot name="content"></slot>
    </div>
    <div v-else class="picBox">
      <template v-if="isWeixin">
        <div class="add" @click="chooseImage()">
          <img src="/static/upload.png" />
        </div>
      </template>
      <template v-else>
        <van-uploader v-if="utm_source == 'iga_baidu'" v-model="files" :before-read="beforeRead" :max-count="num" upload-icon="add" :accept="getFileType(filetype)" :mutiple="androidAttrs2 ? true : false" :capture="androidAttrs2 ? 'camera' : null" preview-size="52" />
        <van-uploader v-else v-model="files" :before-read="beforeRead" :max-count="num" upload-icon="add" :accept="getFileType(filetype)" :mutiple="androidAttrs ? true : false" :capture="androidAttrs ? 'camera' : null" preview-size="52" />
      </template>
    </div>
    <!-- <div class="remarks">
      <div>请上传{{ filetype }}文件，单张文件大小须小于{{ maxsize }}M</div>
    </div> -->
  </div>
</template>

<script>
import { DownloadImage, UploadFile, deleteImg } from '@/api/api'
import { Toast, Dialog, ImagePreview } from 'vant'
import { appHost } from '@/utils/host'
import Compressor from 'compressorjs'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageUploader',
  components: {},
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    num: {
      type: Number,
      default: 1
    },
    maxsize: {
      type: Number,
      default: 20480000
    },
    single: {
      // 单张模式，为真时每次上传会替换前次上传
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      baseFileUrl: appHost,
      filetype: '',
      files: []
    }
  },
  computed: {
    ...mapGetters(['utm_source'])
  },
  watch: {
    value: {
      handler(val) {
        this.files = val
        console.log('watch', val)
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {},
    getFileType(val) {
      if (val == 'word') {
        return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      } else if (val == 'ppt') {
        return '.ppt,.pptx,application/vnd.ms-powerpoint'
      } else if (val == 'pdf') {
        return '.pdf,application/pdf'
      } else {
        return 'image/*'
      }
    },
    checkFileType(ext) {
      if (this.filetype == 'word') {
        return ['doc', 'docx'].indexOf(ext) != -1 ? true : false
      } else if (this.filetype == 'ppt') {
        return ['ppt', 'pptx'].indexOf(ext) != -1 ? true : false
      } else if (this.filetype == 'pdf') {
        return ['pdf'].indexOf(ext) != -1 ? true : false
      } else {
        return true
      }
    },
    preview(file) {
      let images = []
      images.push(file)
      this.$nextTick(() => {
        ImagePreview(images)
      })
    },
    getImgByteSize(data) {
      let size = 0
      if (data) {
        // 获取base64图片byte大小
        const equalIndex = data.indexOf('=') // 获取=号下标
        if (equalIndex > 0) {
          const str = data.substring(0, equalIndex) // 去除=号
          const strLength = str.length
          const fileLength = strLength - (strLength / 8) * 2 // 真实的图片byte大小
          size = Math.floor(fileLength) // 向下取整
        } else {
          const strLength = data.length
          const fileLength = strLength - (strLength / 8) * 2
          size = Math.floor(fileLength) // 向下取整
        }
      }
      return size * 1.657
    },
    async readImages(localIds) {
      for (let i = 0; i < localIds.length; i++) {
        await this.doReadImage(localIds[i])
      }
    },
    doReadImage(localId) {
      let _this = this
      // alert('localId')
      return new Promise(resolve => {
        // alert('getLocalImgData')
        _this.$wechat.getLocalImgData({
          localId: localId,
          success: async response => {
            let localData = response.localData
            _this.picList = response
            let itemSize = await _this.getImgByteSize(localData) //获取base64大小
            console.log(itemSize, 'itemSize')
            if (itemSize > 20480000) {
              Toast('图片过大，请重新选择图片')
            } else {
              // alert("this.uploadImage(serverId)")
              await _this.uploadImage(localId)
            }
            resolve('done!')
          },
          fail: err => {
            console.log(err)
            // Toast(err)
          }
        })
      })
    },
    chooseImage() {
      // this.files.push('https://ecasev2-files.oss-cn-hangzhou.aliyuncs.com/upload/image/202211/08/cbdf5dfc3101f5038055066ae746f83c.jpg?OSSAccessKeyId=LTAI4FdisbELrnUkhP2aFodh&Expires=1667914830&Signature=DkRMsfRJsVTjXymHnATuKfMRS%2F4%3D')
      // this.isLoading = false
      // return
      let _this = this
      let num = parseInt(this.num)
      this.$wechat.chooseImage({
        count: num > 9 ? 9 : num,
        // sizeType: ["compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        success: res => {
          _this.readImages(res.localIds)
          // try {
          //     for(let i in res.localIds) {
          //         const serverId = res.localIds[i];
          //         this.getLocalImgData(serverId)
          //     }
          // } catch (err) {
          //     console.log(err, 'err')
          //     Toast(err.response.data.message);
          // }
        },
        fail: function () {
          Toast('选图失败')
        }
      })
    },
    uploadImage(serverId) {
      // alert('uploadImage')
      return new Promise((resolve, reject) => {
        this.$wechat.uploadImage({
          localId: serverId,
          isShowProgressTips: 1,
          success: res => {
            var serverId = res.serverId // 返回图片的服务器端ID
            Toast.loading({
              duration: 0, // 持续展示 toast
              forbidClick: true
            })
            DownloadImage({
              params: {
                serverId: serverId
              }
            })
              .then(res => {
                if (this.single) {
                  this.files[0] = res
                } else {
                  this.files.push(res)
                }
                this.$emit('change', this.files)
                Toast.clear()
                this.$forceUpdate()
                resolve()
              })
              .catch(err => {
                //  reject(err.response.data.message)
                console.log(err)
                Toast.clear()
                reject(err)
              })
          }
        })
      })
    },
    clear(item, index) {
      Dialog.confirm({
        message: '是否确定删除',
        confirmButtonColor: '#4B99FF',
        title: '温馨提示'
      })
        .then(() => {
          // deleteImg({
          //     attach_index: index,
          // }).then(res => {
          //     Toast('删除成功')
          // }).catch(err => {
          //     Toast(err.response.data.message)
          // })
          // this.ocr();
        })
        .catch(err => {
          console.log(err)
        })
    },
    async compressorImage(file) {
      return new Promise((resolve, reject) => {
        if (file.size <= 1024 * 1024) {
          resolve(file)
        } else {
          new Compressor(file, {
            quality: 0.6,
            converTypes: ['image/png', 'image/jpg'],
            convertSize: 1000000,
            success: result => {
              resolve(result)
            },
            error: err => {
              reject(err)
            }
          })
        }
      })
    },
    async beforeRead(file) {
      console.log('beforeRead', this.files)
      if (file.size > this.maxsize) {
        Toast({
          duration: 1500,
          forbidClick: true,
          message: '上传失败，文件大小超过上传限制！'
        })
        return
      }
      let formData = new window.FormData()
      // const res = await this.compressorImage(file)
      // formData.append('file', res)
      formData.append('file', file)
      this.$nextTick(() => {
        UploadFile(formData)
          .then(res => {
            if (this.checkFileType(res.extension)) {
              if (this.single) {
                this.files[0] = res.file_path
              } else {
                this.files.push(res.file_path)
              }
              this.$emit('change', this.files)
            } else {
              Toast({
                duration: 1500,
                forbidClick: true,
                message: '上传失败，文件类型错误！'
              })
            }
          })
          .catch(err => {
            Toast(err.response.data.message)
            console.log('err', err)
          })
      })
    }
  }
}
</script>

<style lang="scss">
.image-upload {
  position: relative;
  // .van-dialog__header {
  //     color: #2C3E50;
  // }

  .picBox {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .list {
      height: 50px;
      width: 50px;
      margin-right: 10px;
      margin-bottom: 5px;
      position: relative;

      .img {
        height: 100%;
        width: 100%;
      }

      .clear {
        position: absolute;
        right: -2px;
        top: -2px;
        height: 13px;
        width: 13px;
      }
    }

    .add {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-size: 12px;
      color: #272727;
      border: 1px dashed #272727;

      img {
        height: 22px;
        width: 22px;
        margin-bottom: 2px;
      }

      span {
        line-height: 12px;
      }
    }
  }
  .van-field__control .remarks {
    margin-top: 5px;
  }
  .preview-cover {
    position: absolute;
    bottom: 0;
    box-sizing: border-box;
    width: 100%;
    padding: 4px;
    color: #fff;
    font-size: 12px;
    text-align: center;
    background: rgba(0, 0, 0, 0.3);
  }

  .slot-uploader {
    position: relative;
    .slot-cover {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 999;
      overflow: hidden;
    }
    .slot-cover-cont {
      width: 10000px;
      height: 10000px;
    }
  }
}
</style>
